import "./style.scss";
import "sendbird-uikit/dist/index.css";
import { IonPage } from "@ionic/react";
import { getExclusions } from "@src/app/api/exclusion";
import * as shiftApi from "@src/app/api/shift";
import { TabRouterPath } from "@src/app/routing/constant/tabRoute";
import { ShiftInviteRequestMessage } from "@src/appV2/Chat/ShiftInviteRequestMessage";
import { ShiftInviteResponseMessage } from "@src/appV2/Chat/ShiftInviteResponseMessage";
import { ShiftTimeProposalMessage } from "@src/appV2/Chat/ShiftTimeProposalMessage/ShiftTimeProposalMessage";
import { CustomMessageTypes } from "@src/appV2/Chat/type";
import { environmentConfig } from "@src/appV2/environment";
import { CbhFeatureFlag, useCbhFlag, useCbhFlags } from "@src/appV2/FeatureFlags";
import { AppBarHeader, BackButtonLink, PageWithHeader } from "@src/appV2/lib";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { Shift } from "@src/lib/interface/src";
import { useAppSelector } from "@store/index";
import { useEffect, useReducer, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Channel, RenderCustomMessageProps, useSendbirdStateContext } from "sendbird-uikit";

import { getChannelShiftTime, getMessageInputDefinition } from "./channel";
import { fetchChannels } from "./chatProvider";
import { exclusionInitialState, exclusionReducer } from "./exclusionReducer";
import InputEtaMessage from "./InputEtaMessage";
import { SentHomeRequestMessage } from "./SentHomeRequestMessage";
import { SentHomeResponseMessage } from "./sentHomeResponseMessage";

export function ChatPage() {
  const worker = useDefinedWorker();
  const { facilityId } = useParams<{ facilityId: string }>();
  const channels = useAppSelector((store) => store.chatStore.channels);
  const currentChannelUrl = facilityId + "_" + worker.userId;
  const currentChannel = channels.find((channel) => channel.url === currentChannelUrl);
  const [exclusionState, exclusionDispatch] = useReducer(exclusionReducer, exclusionInitialState);
  const sendBirdState = useSendbirdStateContext();
  const sdk = sendBirdState?.stores?.sdkStore?.sdk;
  const dispatch = useDispatch();
  const ldFlags = useCbhFlags();

  const isLateForShiftEnabled = useCbhFlag(CbhFeatureFlag.ENABLE_LATE_FOR_SHIFT, {
    defaultValue: false,
  });

  const urgentShiftsConfig = ldFlags[CbhFeatureFlag.URGENT_SHIFTS_CONFIG] ?? {};
  const [upcomingShifts, setUpcomingShifts] = useState([]);

  useEffect(() => {
    fetchChannels({ sdk, dispatch });
  }, [dispatch, sdk]);

  useEffect(() => {
    async function fetchExclusion() {
      exclusionDispatch({ type: "exclusion/fetching" });
      try {
        const exclusions = await getExclusions({
          facilityId,
          agentId: worker.userId,
        });
        exclusionDispatch({
          type: "exclusion/success",
          // getExclusions returns list of exclusions
          // since we pass only one workerId, it is safe to assume that
          // if there is an exclusion, it is the exclusion between workplace and workerId
          data: { exclusion: exclusions[0] },
        });
      } catch (err: unknown) {
        exclusionDispatch({
          type: "exclusion/failed",
          data: {
            errorMessage: err instanceof Error ? err.message : String(err),
          },
        });
      }
    }

    fetchExclusion();
  }, [worker.userId, currentChannelUrl, facilityId]);

  useEffect(() => {
    if (!isLateForShiftEnabled) {
      return;
    }

    async function checkUpcomingShifts() {
      const nextTwoDaysShiftsList = await shiftApi.fetchNextTwoDaysShifts(worker.tmz ?? "");

      if (!nextTwoDaysShiftsList || (nextTwoDaysShiftsList ?? []).length === 0) {
        return;
      }

      setUpcomingShifts(nextTwoDaysShiftsList);

      await shiftApi.checkAttendanceBulk(environmentConfig.REACT_APP_URGENT_SHIFTS_SERVICE_URL, {
        shifts: nextTwoDaysShiftsList.map((shift: Shift) => {
          return {
            _id: shift._id,
            start: shift.start,
            facility: {
              userId: shift.facility?.userId,
            },
          };
        }),
      });
    }

    checkUpcomingShifts();
  }, [worker.tmz, isLateForShiftEnabled]);

  const { facilityName } = currentChannel?.metadata || {};

  const header = () => (
    <div className="sendbird-custom-chat-header">
      <div className="header-title">
        {currentChannel?.shift?.facility?.name || facilityName || currentChannel?.name}
      </div>
      <div className="header-description">{getChannelShiftTime(currentChannel)}</div>
    </div>
  );

  return (
    <IonPage>
      <PageWithHeader
        appBarHeader={
          <AppBarHeader
            title="Chat"
            leftCta={<BackButtonLink defaultBackTo={TabRouterPath.MY_SHIFTS} />}
          />
        }
      >
        <div className={"chat-container"}>
          <Channel
            renderChatHeader={header}
            channelUrl={currentChannelUrl}
            renderMessageInput={getMessageInputDefinition({
              isLoadingExclusion: exclusionState.isLoading,
              actionBy: exclusionState.exclusion?.actionBy,
              sdk,
            })}
            renderCustomMessage={(message, channel) => {
              /* eslint-disable react/display-name */
              if (message.customType === CustomMessageTypes.FCM_REQUEST) {
                const metaData = channel.getCachedMetaData() as {
                  facilityName: string;
                };
                return () => <SentHomeRequestMessage facilityName={metaData?.facilityName} />;
              }
              if (
                message.customType === CustomMessageTypes.FCM_APPROVAL ||
                message.customType === CustomMessageTypes.FCM_REJECT
              ) {
                return () => <SentHomeResponseMessage message={message} />;
              }

              if (message.customType === CustomMessageTypes.SHIFT_INVITE_REQUEST) {
                return () => <ShiftInviteRequestMessage message={message} />;
              }

              if (message.customType === CustomMessageTypes.SHIFT_INVITE_RESPONSE) {
                return () => <ShiftInviteResponseMessage message={message} />;
              }

              if (message.customType === CustomMessageTypes.SHIFT_TIME_PROPOSAL_CREATED) {
                return () => <ShiftTimeProposalMessage message={message} />;
              }

              if (
                [
                  CustomMessageTypes.SHIFT_TIME_PROPOSAL_UPDATED,
                  CustomMessageTypes.SHIFT_TIME_PROPOSAL_REPLIED,
                ].includes(message.customType as CustomMessageTypes)
              ) {
                // all proposal information is handled in the created message
                return () => <></>;
              }

              if (
                message.customType ===
                CustomMessageTypes.PLACEMENT_APPLICATION_AUTO_INTERVIEW_INVITE
              ) {
                // do not render this message in the mobile app
                return () => <></>;
              }

              if (message.customType === CustomMessageTypes.INPUT_ETA) {
                // message.data contains single quote, make sure we remove it before JSON.parse
                const messageData = message.data
                  ? (JSON.parse(message.data.replace(/'/g, '"')) as {
                      shiftId: string;
                    })
                  : null;

                return () => {
                  const shift = upcomingShifts.find(
                    (shift: Shift) => shift._id === messageData?.shiftId
                  );
                  if (!shift) {
                    return <></>;
                  }
                  return (
                    <InputEtaMessage
                      shift={shift}
                      message={message}
                      urgentShiftsConfig={urgentShiftsConfig}
                    />
                  );
                };
              }

              if (!message.customType && message.data) {
                try {
                  const data = JSON.parse(message.data);
                  const chatTitle = `${data.workplaceUserName.split(" ")[0]} from ${
                    message._sender.nickname
                  }`;
                  message._sender.friendName = chatTitle;
                } catch (error) {
                  console.error(error);
                }
              }
              return null as unknown as RenderCustomMessageProps;
            }}
          />
        </div>
      </PageWithHeader>
    </IonPage>
  );
}
