import { getSendHomeRequestMessage } from "./messages";
import "./sentHomeMessage.scss";

interface SentHomeRequestMessageProps {
  facilityName?: string;
}

export const SentHomeRequestMessage = ({
  facilityName,
}: SentHomeRequestMessageProps): JSX.Element => {
  return (
    <div className="fcm-message-container">
      <div className="fcm-avatar-container">
        <div>
          <img src="assets/logo/bunny.png" />
        </div>
      </div>
      <div className="fcm-message-body">
        <span>Clipboard</span>
        <div className="message-wrapper">
          <div>
            <div>{getSendHomeRequestMessage(facilityName)}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
